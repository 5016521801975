import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "./blog-post.css"

export default function BlogPostTemplate({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter = {}, html, timeToRead, wordCount = {} } = markdownRemark
  const featuredImgFluid = frontmatter.featuredImage.childImageSharp.fluid
  return (
    <Layout>
      <SEO title={`${frontmatter.title}`} description={frontmatter.description} />
      <article>
        <div className="w-100 relative md:mb-5">
          <Img className="intro-image" fluid={featuredImgFluid} />
          <div className="title pb-5 md:pb-10 md:mb-5 md:absolute md:translate-bottom-center md:bottom-0 md:left-1/2 md:rounded-lg bg-white">
            <div className="mx-8 pt-4">
              <h1 className="text-red-500">{frontmatter.title}</h1>
              <span className="text-sm text-gray-500 pt-5">
                Napísala: <span className="text-black">{frontmatter.author}</span>,{" "}
                <span className="capitalize pr-3">{frontmatter.date}</span>
              </span>
              <span className="text-gray-500 text-sm text-gray-900">
                <br />
                <i className="fas fa-book-reader pr-1"></i>
                {timeToRead} min. ({wordCount.words} slov)
              </span>
            </div>
          </div>
        </div>
        <div className="flex md:pt-5 justify-center">
          <div className="leading-relaxed article md:max-w-5xl">
            <div className="article" dangerouslySetInnerHTML={{ __html: html }} />
          </div>
        </div>
      </article>
    </Layout>
  )
}
export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      id
      html
      timeToRead
      wordCount {
        words
      }
      frontmatter {
        author
        path
        date(locale: "sk", formatString: "LL, dddd")
        title
        description
        featuredImage {
          childImageSharp {
            fluid(quality: 80) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
